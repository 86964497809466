*:not(h1, h2, h3, h4, h5, h6, mark) {
  font-family: 'Lato', sans-serif !important;
}

h1, h2, h3, h4, h5, h6, .u-font-family-acme {
  font-family: 'Acme', sans-serif !important;
  font-weight: 400 !important;
}

body {
  margin: 0;
}

mark {
  background-color: var(--spectrum-global-color-static-yellow-200);
}

.link {
  color: unset;
  text-decoration: unset;
  border-radius: var(--spectrum-alias-border-radius-regular);
  transition: box-shadow var(--spectrum-global-animation-duration-100) ease-in-out;
  outline: none;
}

.link:focus-visible {
  box-shadow: 0 0 0 2px var(--spectrum-alias-border-color-focus);
}

.job-description h2 {
  margin-top: var(--spectrum-alias-heading3-margin-top);
  margin-bottom: var(--spectrum-alias-heading-margin-bottom);
  font-size: var(--spectrum-alias-heading3-text-size);
  font-weight: var(--spectrum-alias-heading-text-font-weight-quiet);
  line-height: var(--spectrum-alias-heading-text-line-height);
  color: var(--spectrum-alias-heading-text-color);
}

.job-description h2 [data-slate-string] {
  font-family: 'Acme', sans-serif !important;
}

.job-description p,
.job-description ul,
.job-description ol {
  margin-top: 0;
  margin-bottom: var(--spectrum-alias-body-margin-bottom);
}

.job-description ul,
.job-description ol {
  padding-left: var(--spectrum-global-dimension-size-300);
}

.job-description a {
  color: inherit;
  overflow-wrap: anywhere;
  outline: none;
}

.job-description a:focus-visible {
  text-decoration-style: double;
}

.spectrum-ActionButton_e2d99e,
.spectrum-Button_e2d99e,
.spectrum-ClearButton_e2d99e,
.spectrum-FieldButton_e2d99e,
.spectrum-LogicButton_e2d99e {
  touch-action: initial !important; /* Re-enable touch scrolling. */
}

.spectrum-ActionButton_8890ad,
.spectrum-Button_8890ad,
.spectrum-ClearButton_8890ad,
.spectrum-FieldButton_8890ad,
.spectrum-LogicButton_8890ad,
.spectrum-Stepper-button_8890ad {
  touch-action: initial !important; /* Re-enable touch scrolling. */
}

.text h1,
.text h2,
.text h3 {
  margin-bottom: var(--spectrum-alias-heading-margin-bottom);
  font-weight: var(--spectrum-alias-heading-text-font-weight-quiet);
  line-height: var(--spectrum-alias-heading-text-line-height);
  color: var(--spectrum-alias-heading-text-color);
}

.text h1 {
  margin-top: var(--spectrum-alias-heading1-margin-top);
  font-size: var(--spectrum-alias-heading1-text-size);
}

.text h2 {
  margin-top: var(--spectrum-alias-heading2-margin-top);
  font-size: var(--spectrum-alias-heading2-text-size);
}

.text h3 {
  margin-top: var(--spectrum-alias-heading3-margin-top);
  font-size: var(--spectrum-alias-heading3-text-size);
}

.text p,
.text ul {
  margin-top: 0;
  margin-bottom: var(--spectrum-alias-body-margin-bottom);
}

.text ul {
  padding-left: var(--spectrum-global-dimension-size-300);
}

.text a {
  color: inherit;
  outline: none;
}

.text a:focus-visible {
  text-decoration-style: double;
}
